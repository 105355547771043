<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
        <div>
            <v-icon small class="green--text mr-1">mdi-plus-circle</v-icon>
      <a v-bind="attrs" v-on="on" href="#"> 
          <span class="caption">新建员工信息</span> 
          </a>
          </div>
    </template>

    <v-card>
      <v-card-title>
        <h3 class="subheading">编辑员工信息</h3>
      </v-card-title>

      <v-card-text>
        <v-form class="pa-3" ref="form">
          <v-row align="baseline" class="short_row">
            <v-col cols="6">
              <v-text-field 
                dense
                v-model="staff.name"
                label="姓名"
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="baseline" class="short_row">
            <v-col cols="6">
              <v-text-field
                dense
                v-model="staff.email"
                label="Email"
                :disabled="staff_id != ''"
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>
          
          <v-row align="baseline" class="short_row">
            <v-col cols="6">
              <v-text-field
                dense
                v-model="staff.phone_number"
                label="手机"
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="baseline" class="short_row">
            <v-col cols="6">
              <v-text-field
                dense
                v-model="staff.title"
                label="职位"
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="baseline" class="short_row">
            <v-col cols="6">
              <v-select
                v-model="staff.level"
                :items="levels"
                item-text="name"
                item-value="name"
                label="级别"
                persistent-hint
                single-line
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="baseline" class="short_row">
            <v-col cols="6">
              <v-select
                v-model="staff.dept"
                :items="depts"
                item-text="name"
                item-value="name"
                label="部门"
                persistent-hint
                single-line
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="ma-4" @click="submit_staff">确定</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      staff: {},
      depts: [],
      levels:[],
      staff_id:'',

      inputRules: [(v) => v.length >= 3 || "Minimum length is 3 characters"],
    };
  },

    created: function() {
     // Make a request for a usr with a given ID
     this.get_depts();
     this.get_levels();
      
   }, 
  methods: {
    submit_staff() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        let that = this;
        if (this.staff_id) {
            formData.append("id", this.staff_id);
        } else {
            formData.append("email", this.staff.email);
        }
        formData.append("name", this.staff.name);
        formData.append("title", this.staff.title);
        formData.append("phone_number", this.staff.phone_number);
        formData.append("level", this.staff.level);
        formData.append("dept", this.staff.dept);

        console.log(formData)

        this.$http
          .post("/system/update_account", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
              console.log(response.data)
              if (response.data.status == "success") {
                console.log("SUCCESS!");
                that.dialog = false;
                that.$emit("add-staff");
                }
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
              console.log('always run...')
            that.staff = {};
            that.staff_id = ''
          });
      }
    },

    get_depts() {
      console.log("get_depts");
      let that = this;
      this.$http
        .get("/system/get_depts/")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.depts = response.data.depts;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    get_levels() {
      console.log("get_levels");
      let that = this;
      this.$http
        .get("/system/get_levels/")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.levels = response.data.levels.filter(level => {
              let account_info = JSON.parse(that.$store.getters.accountInfo)
              return level.code < account_info.level_code;
            });
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },
  },
};
</script>

<style scoped>
.short_row {
  height: 60px;
}
.high_row {
  height: 150px;
}
</style>
