<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs" v-on="on" href="#"> 新增部门 </a>
    </template>

    <v-card>
      <v-card-title>
        <h3 class="subheading">新增部门</h3>
      </v-card-title>

      <v-card-text>
        <v-form class="pa-3" ref="form">
          <v-row align="baseline" class="short_row">
            <v-col cols="6">
              <v-text-field
                dense
                v-model="dept_name"
                label="部门名称"
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="baseline" class="short_row">
            <v-col cols="6">
              <v-text-field
                dense
                v-model="dept_englishname"
                label="英文名称"
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="baseline" class="short_row">
            <v-col cols="6">
              <v-select
                v-model="dept_leader"
                :hint="`${dept_leader.name}, ${dept_leader.email}`"
                :items="staff_list"
                item-text="name"
                item-value="email"
                label="部门负责人"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="ma-4" @click="submit_dept">确定</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      dept_name: "",
      dept_englishname: "",
      dept_leader: {'email':'', 'name':''},
      staff_list: [],

      inputRules: [(v) => v.length >= 3 || "Minimum length is 3 characters"],
    };
  },

    created: function() {
     // Make a request for a usr with a given ID
     this.get_staff_list();
      
   }, 
  methods: {
    submit_dept() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        let that = this;
        formData.append("name", this.dept_name);
        formData.append("english_name", this.dept_englishname);
        formData.append("leader", this.dept_leader.email);
        this.$http
          .post("/system/update_dept/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
              console.log(response.data)
              if (response.data.status == "success") {
                console.log("SUCCESS!");
                that.dialog = false;
                that.$emit("add-dept");
                }
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            that.dept_name = "";
            that.dept_englishname = "";
            that.dept_leader = {'email':'', 'name':''};
          });
      }
    },

    get_staff_list() {
      console.log("get_staff_list");
      let that = this;
      this.$http
        .get("/system/get_staff_list/")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.staff_list = response.data.staff_list;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },
  },
};
</script>

<style scoped>
.short_row {
  height: 60px;
}
.high_row {
  height: 150px;
}
</style>
