<template>
  <div class="departments">
    <h1 class="subheading grey--text ma-4">部门管理</h1>
    <v-row class="ma-4">
        <div class="ml-5"><AddDeptDlg v-if="is_owner" @add-dept="get_depts"/></div>
      
     <v-spacer></v-spacer>
      
      <!-- <span class="body-1">Click</span> <a class="mx-1" href="#">here</a>  <span> to Add an Actual Controller</span> -->
    </v-row>
    <v-container class="ma-5" fluid >
      <v-row class="mb-3"> </v-row>
      <v-card v-for="dept in depts" :key="dept.name">
        <v-row class="pa-3">
          <v-col xs="12" md="6">
            <div class="caption grey--text">部门名称</div>
            <div>{{ dept.name }}</div>
          </v-col>
          <v-col xs="6" sm="4" md="2">
            <div class="caption grey--text">英文名称</div>
            <div>{{ dept.english_name }}</div>
          </v-col>
          <v-col xs="6" sm="4" md="2">
            <div class="caption grey--text">部门负责人</div>
            <div>{{ dept.leader }}</div>
          </v-col>
          <v-col xs="6" sm="4" md="2">
            <div class="caption grey--text mb-2">详情</div>
            <router-link :to="`/get_staff_list/${dept.id}`" tag="div">
              <span class="text-decoration-underline blue--text">查看</span>
            </router-link>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddDeptDlg from '@/components/AddDeptDlg.vue'

export default {
  components: {AddDeptDlg},
  mounted: function () {
    console.log("hello");
  },

  data() {
    return {
      depts: [],
    };
  },
  computed : {
      is_owner: function() {
        let account_info = JSON.parse(this.$store.getters.accountInfo)
        return account_info.email === account_info.owner;
      }
    },

  created: function () {
    // Make a request for a usr with a given ID
    this.get_depts();
  },
  methods: {
    get_depts() {
      console.log("get_depts");
      let that = this;
      this.$http
        .get("/system/get_depts/")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.depts = response.data.depts;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },
  },
};
</script>

<style scoped>
/* .project.complete {
  border-left: 4px solid #3cd1c2;
}

.project.ongoing {
  border-left: 4px solid orange;
}

.project.overdue {
  border-left: 4px solid tomato;
}

.v-chip.complete {
  background:#3cd1c2 !important;
}

.v-chip.ongoing {
  background: orange !important;
}

.v-chip.overdue {
  background: tomato !important;
} */
</style>
