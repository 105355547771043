<template>
  <div class="departments">
    <h1 class="subheading grey--text ma-4">员工管理</h1>
    <v-row class="ma-4">
      <div class="ml-5">
        <AddStaffDlg v-if="is_manager" ref="addStaffDlg" @add-staff="get_staff_list" />
      </div>
      <v-spacer></v-spacer>

      <!-- <span class="body-1">Click</span> <a class="mx-1" href="#">here</a>  <span> to Add an Actual Controller</span> -->
    </v-row>
    <v-container class="ma-5" fluid>
      <v-row class="mb-3"> </v-row>
      <v-card v-for="staff in staff_list" :key="staff.email">
        <v-row class="pa-3">
          <v-col cols="3">
            <div class="caption grey--text mb-2">Email</div>
            <div class="caption"><v-icon small class="mr-1">mdi-email</v-icon>{{ staff.email }}</div>
          </v-col>
          <v-col cols="2">
            <div class="caption grey--text mb-2">姓名</div>
            <div :class="`caption ${staff.status=='关闭'?'closed':''}`">
                <v-icon v-if="staff.status=='打开'" small class="mr-1">mdi-account</v-icon>
                <v-icon v-if="staff.status=='关闭'" small class="mr-1 red--text">mdi-account-off</v-icon>
                {{ staff.name }}
            </div>
          </v-col>
          <v-col cols="2">
            <div class="caption grey--text mb-2">手机</div>
            <div class="caption"><v-icon small class="mr-1">mdi-cellphone</v-icon>{{ staff.phone_number }}</div>
          </v-col>
          <v-col cols="1">
            <div class="caption grey--text mb-2">级别</div>
            <div class="caption"><v-icon small class="mr-1">mdi-clipboard-account</v-icon>{{ staff.level }}</div>
          </v-col>
          <v-col cols="1">
            <div class="caption grey--text mb-2">部门</div>
            <div class="caption"><v-icon small class="mr-1">mdi-domain</v-icon>{{ staff.dept }}</div>
          </v-col>
          <v-col cols="1">
            <div class="caption grey--text mb-2">创建人</div>
            <div class="caption"><v-icon small class="mr-1">mdi-face-agent</v-icon>{{ staff.create_by }}</div>
          </v-col>
          <v-col cols="2">
            <div class="caption grey--text mb-1">操作</div>
            <div>
            <span v-if="staff.status == '打开' && staff.level_code < myLevel"
              class="px-2 pointer"
              @click.stop="close_account(staff.id)"
              ><v-icon small class="red--text">mdi-account-cancel</v-icon> </span>
              <span v-if="staff.status == '关闭'"
              class="px-2 blue--text pointer"
              @click.stop="open_account(staff.id)"
              ><v-icon small class="green--text">mdi-account-plus</v-icon> </span>

              <span v-if="staff.status == '打开' && staff.level_code < myLevel"
              class="px-2 pointer"
              @click.stop="edit_account(staff)"
              ><v-icon small>mdi-pencil</v-icon></span>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddStaffDlg from "@/components/AddStaffDlg.vue";

export default {
  components: { AddStaffDlg },
  mounted: function () {
    console.log("hello");
  },

  data() {
    return {
      staff_list: [],
    };
  },
  computed: {
    is_manager: function () {
      let account_info =  JSON.parse(this.$store.getters.accountInfo)
      return account_info.level_code > 100;
    },
    myLevel: function() {
      let account_info = JSON.parse(this.$store.getters.accountInfo)
        return account_info.level_code
    }
  },

  created: function () {
    // Make a request for a usr with a given ID
    this.get_staff_list();
  },
  methods: {
    get_staff_list() {
      console.log("get_staff_list");
      let that = this;
      this.$http
        .get("/system/get_staff_list/")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.staff_list = response.data.staff_list;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    close_account(id) {
      let formData = new FormData();
      let that = this;
      formData.append("id", id);
      
      console.log(formData);

      this.$http
        .post("/system/close_account", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          console.log(response.data);
          if (response.data.status == "success") {
            console.log("SUCCESS!");
            that.get_staff_list()
           
          }
        })
        .catch(function () {
          console.log("FAILURE!!");
        })
    },

    open_account(id) {
      let formData = new FormData();
      let that = this;
      formData.append("id", id);
      
      console.log(formData);

      this.$http
        .post("/system/open_account", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          console.log(response.data);
          if (response.data.status == "success") {
            console.log("SUCCESS!");
            that.get_staff_list()
           
          }
        })
        .catch(function () {
          console.log("FAILURE!!");
        })
    },

    edit_account(staff) {
        this.$refs.addStaffDlg.staff = JSON.parse(JSON.stringify(staff))
        this.$refs.addStaffDlg.staff_id = staff.id
        this.$refs.addStaffDlg.dialog =  true
    }
  },
};
</script>

<style scoped>

.closed {
    background-color: lightgrey;
}
/* .project.complete {
  border-left: 4px solid #3cd1c2;
}

.project.ongoing {
  border-left: 4px solid orange;
}

.project.overdue {
  border-left: 4px solid tomato;
}

.v-chip.complete {
  background:#3cd1c2 !important;
}

.v-chip.ongoing {
  background: orange !important;
}

.v-chip.overdue {
  background: tomato !important;
} */
</style>
